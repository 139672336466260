import React from 'react'
import { Link } from 'gatsby'

/**
 *
 * @param {row} param0
 * @returns Number - if row is 1, then should have 2 columns, if row is 2, then should have 3 columns
 *
 * @param {number} param1
 * @returns Number - 1st card in 1st row should have a different column start (to center it)
 *
 */
export default function Card ({ row, number, imageURL, title, description }) {
  return (
    <div className={`col-span-1 lg:col-span-1 ${number === 1 ? 'lg:col-start-3' : ''} lg:col-span-4 max-w-xs mx-auto px-4 mb-8`} >
      <div className="h-full rounded-md overflow-hidden" style={{ boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)' }}>
        <div style={{ height: '180px', overflow: 'hidden' }}>
          <img className="w-full object-cover object-center min-h-full"
              src={ imageURL }
              alt="blog"
          />
        </div>

        <div className="py-6 px-3">
          <h2 className="text-center text-lg font-medium text-gray-900 mb-5 h-12">{ title }</h2>
          <p className="leading-relaxed mb-3 h-36">{ description }</p>
          <Link to="/services#commerical" className="text-blue-700 text-center hover:underline">
            <p>Learn more</p>
          </Link>
        </div>

      </div>
  </div>

  )
}
